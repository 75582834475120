import apiClient from '../services/api';

export default {
  state: {
    facilities: []
  },
  reducers: {
    setFacilities(state, payload = []) {
      return { ...state, facilities: payload };
    },
    clearFacilities(state) {
      return { ...state, facilities: [] };
    }
  },
  effects: dispatch => ({
    async searchFacilities(searchString) {
      const results = await apiClient.get('facilities/search', {
        q: searchString
      });

      if (results !== null) {
        dispatch.facilities.setFacilities(results);
      }
    },
    async getAllFacilities() {
      const results = await apiClient.get('facilities');
      if (results !== null) {
        dispatch.facilities.setFacilities(results);
      }
    },
    clearFacilities() {
      dispatch.facilities.clearFacilities();
    }
  })
};
