import React from 'react';
import { routeNode } from 'react-router5';
import ReferralForm from '../../components/Forms/ReferralForm.component';
import { Container } from '@material-ui/core';
import './ReferralForm.style.scss';
class ReferralFormContainer extends React.Component {
  render() {
    return (
      <Container className="referral-form-container">
        <ReferralForm />
      </Container>
    );
  }
}

export default routeNode('referral-form')(ReferralFormContainer);
