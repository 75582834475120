import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';
export default function configureRouter() {
  const router = createRouter([
    {
      name: 'intro-page',
      path: '/',
      children: [
        {
          name: 'intro-page',
          path: ':programId/?:userId'
        }
      ]
    },
    {
      name: 'referral-form',
      path: '/refer'
    }
  ]);

  router.usePlugin(
    browserPlugin({
      useHash: true
    })
  );

  router.start();
  return router;
}
