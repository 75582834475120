import apiClient from '../services/api';
import store from 'store';
export default {
  state: {
    mobile_number: null,
    birth_date: null,
    sex: null,
    facility_id: null
  },
  effects: dispatch => ({
    async createReferral(data) {
      // Static for TB
      data.program_code = 'tb-ihss';
      data.platform_code = 'swip';
      try {
        const response = await apiClient.post('programs/refer', data);
        if (response.status === 200) {
          dispatch.ui.showPopupModal({
            type: 'patient-submit-success',
            title: 'Entry has been recorded.',
            message: 'Please inform the patient to confirm their enrollment to the project by replying to the SMS that they would receive. Thank you.',
            defaultClose: () => {
              window.location.replace(`/?userId=${store.get('userId')}`);
            }
          });
        }

        return response;
      } catch (e) {
        console.error(e);
      }
    }
  })
};
