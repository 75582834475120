import React, { createElement } from 'react';
import { routeNode } from 'react-router5';
import { Container } from '@material-ui/core';

import PopupAlert from '../../components/PopupAlert/PopupAlert.component';
class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.routes = {
      'intro-page': require('../Introduction/Introduction.container').default,
      'referral-form': require('../ReferralForm/ReferralForm.container').default
    };
  }
  render() {
    const { route } = this.props;
    const component = createElement(this.routes[route.name]);

    return (
      <Container>
        <PopupAlert />
        {component}
      </Container>
    );
  }
}

export default routeNode('')(MainPage);
