import React from 'react';
import classnames from 'classnames';
import './FormField.style.scss';
import { Input, NativeSelect, InputLabel, FormControl, FormHelperText } from '@material-ui/core';

export class TextfieldComponent extends React.Component {
  render() {
    const { input, defaultValue, readOnly, custom } = this.props;
    if (defaultValue) input.value = defaultValue;
    const inputClass = classnames({
      'form-control-plaintext': readOnly ? true : false,
      'form-control': !readOnly ? true : false
    });
    return (
      <FormControl
        margin="normal"
        fullWidth={typeof custom.fullWidth !== 'undefined' ? custom.fullWidth : true}
        error={custom.errors && custom.errors[input.name] ? true : false}
        id={input.name}
      >
        <InputLabel htmlFor={custom.label} shrink>
          {custom.label}
        </InputLabel>
        <Input
          {...input}
          disableUnderline={readOnly}
          label={custom.label}
          onChange={input.onChange}
          value={input.value}
          type={input.type}
          readOnly={readOnly}
          placeholder={custom.placeholder}
          className={inputClass}
          required={custom.required}
          inputProps={{
            min: custom.min,
            max: custom.max,
            pattern: custom.pattern,
            maxLength: custom.maxLength
          }}
        />
        {custom.errors && custom.errors[input.name] ? <FormHelperText id={`helper-for-${input.name}`}>{custom.errors[input.name]}</FormHelperText> : null}
      </FormControl>
    );
  }
}
export class DropDownSelectComponent extends React.Component {
  render() {
    const { name, input, disabled, custom, readOnly } = this.props;
    const inputClass = classnames({
      'form-control-plaintext': disabled ? true : false,
      'form-control': !disabled ? true : false
    });
    return (
      <FormControl id={input.name} required={custom.required} fullWidth margin="normal" error={custom.errors && custom.errors[input.name] ? true : false}>
        <InputLabel htmlFor={name} shrink>
          {custom.label}
        </InputLabel>
        <NativeSelect
          input={
            <Input
              disableUnderline={readOnly}
              label={custom.label}
              // invalid={touched && !!error}
              {...input}
              onChange={input.onChange}
              value={input.value}
              type={input.type}
              readOnly={readOnly}
              placeholder={custom.placeholder}
              className={inputClass}
              required={custom.required}
              min={custom.min}
              max={custom.max}
              pattern={custom.pattern}
            />
          }
          className={inputClass}
          {...input}
          name={name}
          onChange={input.onChange}
          disabled={readOnly}
          required={custom.required}
        >
          {custom.options.map((option, index) => (
            <option style={option.style} key={index} value={option.value} defaultValue={option.defaultValue} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </NativeSelect>
        {custom.errors && custom.errors[input.name] ? <FormHelperText id={`helper-for-${custom.label}`}>{custom.errors[input.name]}</FormHelperText> : null}
      </FormControl>
    );
  }
}
