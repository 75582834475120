import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { routeNode } from 'react-router5';
import { TextfieldComponent, DropDownSelectComponent } from '../FormField/FormField.component';
import { Grid, Button, CircularProgress, makeStyles } from '@material-ui/core';
import _ from 'lodash';
const Loader = () => {
  const useStyles = makeStyles(() => ({
    buttonProgress: {
      color: '#f7f7f7',
      position: 'absolute',
      left: '50%',
      marginTop: 5,
      marginLeft: -12
    }
  }));
  const materialClasses = useStyles();

  return <CircularProgress id="loader" size={24} className={materialClasses.buttonProgress} />;
};
class ReferralForm extends Component {
  state = {
    showConfirmation: false,
    formValues: null,
    loading: false,
    inputErrors: {}
  };

  onSubmit = values => {
    this.triggerConfirm(values);
  };

  validate = values => {
    const inputErrors = {};
    if (!values['mobile_number']) {
      inputErrors['mobile_number'] = 'Required';
    }

    if (values['mobile_number'] && values['mobile_number'].length !== 11) {
      inputErrors['mobile_number'] = 'Should be at least 11 digits. Ex. 0917XXXXXXX';
    }

    if (!values['birth_date']) {
      inputErrors['birth_date'] = 'Required';
    }

    if (!values['sex']) {
      inputErrors['sex'] = 'Required';
    }
    if (!values['facility_id']) {
      inputErrors['facility_id'] = 'Required';
    }

    return inputErrors;
  };
  triggerConfirm = values => {
    const inputErrors = this.validate(values);
    if (!_.isEmpty(inputErrors)) {
      this.setState({
        inputErrors,
        formValues: values
      });
      return;
    }
    this.setState({
      inputErrors: {},
      showConfirmation: true,
      formValues: values
    });
  };

  finalSubmit = async () => {
    this.setState({ loading: true });
    const response = await this.props.createReferral(this.state.formValues);

    const statesToUpdate = {
      loading: false
    };
    if (response.status !== 200) {
      statesToUpdate.showConfirmation = false;
      statesToUpdate.inputErrors = response.data.inputs;
    }

    this.setState(statesToUpdate);
  };
  triggerEdit = () => {
    this.setState({
      showConfirmation: false
    });
  };

  async componentDidMount() {
    await this.props.getAllFacilities();
  }
  render() {
    const { showConfirmation, loading, inputErrors } = this.state;
    const { facilities } = this.props.facilitiesStore;
    const facilitiesOptions = [
      {
        disabled: true,
        label: 'Search for a facility',
        value: ''
      }
    ];
    if (facilities.length !== 0) {
      // eslint-disable-next-line
      facilities.map(facility => {
        facilitiesOptions.push({
          label: facility.name,
          value: facility.id
        });
      });
    }
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{
          mobile_number: null,
          birth_date: null,
          sex: null,
          facility_id: null
        }}
        render={({ handleSubmit }) => {
          return (
            <div className="referral-form">
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  name="mobile_number"
                  type="tel"
                  pattern="[0-9]*"
                  custom={{
                    label: 'Mobile Number',
                    placeholder: "Enter patient's phone number",
                    required: true,
                    maxLength: 11,
                    errors: inputErrors
                  }}
                  readOnly={showConfirmation ? true : false}
                  render={props => <TextfieldComponent {...props} />}
                />
                <Field
                  name="birth_date"
                  type="date"
                  custom={{
                    label: 'Birth Date',
                    required: true,
                    errors: inputErrors
                  }}
                  readOnly={showConfirmation ? true : false}
                  render={props => <TextfieldComponent {...props} />}
                />
                <Grid container>
                  <Grid item>
                    <Field
                      name="sex"
                      custom={{
                        label: 'Sex',
                        options: [
                          {
                            label: '-',
                            value: ''
                          },
                          {
                            label: 'Male',
                            value: 'male'
                          },
                          {
                            label: 'Female',
                            value: 'female'
                          }
                        ],
                        errors: inputErrors
                      }}
                      readOnly={showConfirmation ? true : false}
                      render={props => <DropDownSelectComponent {...props} />}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Field
                    name="facility_id"
                    type="search"
                    custom={{
                      label: 'Preferred facility',
                      options: facilitiesOptions,
                      errors: inputErrors
                    }}
                    readOnly={showConfirmation ? true : false}
                    render={props => <DropDownSelectComponent {...props} />}
                  />
                </Grid>
                <Grid container className="submit-btn-container">
                  {!showConfirmation ? (
                    <Button id="submit-btn" type="submit" className="submit-btn">
                      SUBMIT
                    </Button>
                  ) : null}
                </Grid>
                {showConfirmation ? (
                  <React.Fragment>
                    <Grid className="disclaimer-container">
                      <p id="disclaimer" className="disclaimer">
                      You are about to submit the details in this form. By submitting, you are confirming that the referred individual has voluntarily provided his/her information.
                      </p>
                    </Grid>
                    <Grid>
                      <Grid>
                        <Button id="confirm-btn" type="button" className={!loading ? 'confirm-btn' : 'disable-confirm-btn'} onClick={this.finalSubmit}>
                          CONFIRM
                        </Button>
                        {loading ? (
                          <Loader />
                        ) : (
                          <Button type="button" className="edit-btn" onClick={this.triggerEdit}>
                            Edit
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </form>
            </div>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  referral: state.referral,
  facilitiesStore: state.facilities
});

const mapDispatchToProps = ({ referral, facilities }) => ({
  ...referral,
  ...facilities
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(routeNode('')(ReferralForm));
