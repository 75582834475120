//Components
import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { connect } from 'react-redux';

//SCSS
import './style.scss';

const popupRoot = document.getElementById('popup');
/**
 * [Component of the Popup Alert Modal.]
 * @props string            {title}     [req]  [Title of the alert that will be displayed on top of the modal.]
 * @props boolean           {open}      [req]  [Toggle Key of the alert modal.]
 * @props function          {onClose}   [req]  [Function to invoke on modal close.]
 * @props string            {type}      [req]  [alert, success, warning, danger]
 * @props string            {message}   [req]  [content of the alert]
 * @props array(objects)    {actions}   [x]    [Array of actions to be placed in the modal]
 *      @attribute string       {label}     [req]  [Label to be shown on the button]
 *      @attribute function     {onClick}   [req]  [Function to call on action click]
 */
class PopupAlertComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.el = document.createElement('div');
  }

  componentDidMount() {
    popupRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    popupRoot.appendChild(this.el);
  }

  defaultModal = (ui, props) => {
    return (
      <Dialog className="popup-alert-container" size="sm" onClose={ui.popupAlert.onClose} open={ui.popupAlert.open}>
        <DialogTitle className={ui.popupAlert.type}>{ui.popupAlert.title}</DialogTitle>
        <DialogContent>
          <p dangerouslySetInnerHTML={{ __html: ui.popupAlert.message }} />
        </DialogContent>
        {ui.popupAlert.actions && !ui.popupAlert.disableCloseButton ? (
          <DialogActions>
            {ui.popupAlert.actions.map((action, actionIndex) => (
              <Button key={`popup-action-button-${actionIndex}`} onClick={action.onClick}>
                {action.label}
              </Button>
            ))}
            <Button key={`popup-action-button-cancel`} onClick={props.closePopupModal}>
              Close
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  };

  patientSuccessModal = (ui, props) => {
    return (
      <Dialog
        className="popup-alert-container"
        size="sm"
        onClose={ui.popupAlert.onClose}
        open={ui.popupAlert.open}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle className={ui.popupAlert.type}>
          <Grid>
            <img
              className="patient-submit-success-icon img-fluid"
              src={require('./assets/images/patient-submit-success-icon.png')}
              alt="patient-submit-success-icon"
            />
          </Grid>
          <Grid id="patient-submit-success-title" className="patient-submit-success-title">
            <Grid size="6">{ui.popupAlert.title}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={ui.popupAlert.type + '-body'}>
          <p dangerouslySetInnerHTML={{ __html: ui.popupAlert.message }} />
        </DialogContent>
        <DialogActions>
          <Button id="close-patient-success-prompt" className="close-btn" onClick={ui.popupAlert.defaultClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { ui } = this.props;
    const modalMarkdown = ui.popupAlert.type === 'patient-submit-success' ? this.patientSuccessModal : this.defaultModal;
    return ReactDOM.createPortal(modalMarkdown(ui, this.props), this.el);
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = ({ ui }) => {
  return {
    ...ui
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupAlertComponent);
