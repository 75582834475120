import apiClient from '../services/api';
import store from 'store';
export default {
  state: {},
  effects: () => ({
    async authorizeUser(userId) {
      const results = await apiClient.post('auth/tb-authorize', {
        user_id: userId
      });

      if (results !== null) {
        const { data } = results;
        if (data.authorized) {
          store.set('userId', userId);
          return true;
        }
        store.remove('userId');
        return false;
      }

      store.remove('userId');
      return false;
    }
  })
};
