import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StoreContext } from 'redux-react-hook';
import { RouterProvider } from 'react-router5';
import './config';
import './index.scss';
import createRouter from './create-router';
import * as serviceWorker from './serviceWorker';
import MainPage from './containers/MainPage/MainPage.container';
import stores from './stores/index';
const router = createRouter(true);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactDOM.render(
  <StoreContext.Provider value={stores}>
    <Provider store={stores}>
      <RouterProvider router={router}>
        <MainPage />
      </RouterProvider>
    </Provider>
  </StoreContext.Provider>,
  document.getElementById('root')
);
