import React from 'react';
import { connect } from 'react-redux';
import { routeNode, BaseLink } from 'react-router5';
import { Grid, LinearProgress } from '@material-ui/core';
import './assets/Introduction.style.scss';

// 2 Paragraphs, 142 words
class Introduction extends React.Component {
  state = {
    showContainerLoader: false,
    isAuthorized: false
  };
  componentDidMount() {
    const { authorizeUser, route } = this.props;
    this.setState({ showContainerLoader: true });

    authorizeUser(route.params['userId']).then(isAuthorized => {
      this.setState({ showContainerLoader: false, isAuthorized });
    });
  }
  render() {
    const { router } = this.props;
    const { isAuthorized } = this.state;

    if (this.state.showContainerLoader) {
      return <LinearProgress />;
    }
    return (
      <Grid container className="intro-container">
        <Grid id="title" className="title">
          <img className="img-fluid title-banner" src={"https://storage.googleapis.com/swiperx-rt/assets/images/tagline.png"} alt="title" />
        </Grid>
        <Grid item className="intro-content">
          <p className="about">About the Referral Tool</p>
          <p>
          Refer self-medicating pharmacy patients to partner health facilities immediately through this form for proper diagnosis and treatment of the following symptoms:
          </p>
          <img className="img-fluid tb-symptoms" src={"https://storage.googleapis.com/swiperx-rt/assets/images/symptoms.png"} alt="tb-symptoms" />
          <p className="about">About the SwipeRx eReferral Program</p>
          <p id="about">
            The SwipeRx TB eReferral Program is an innovative approach to identify and refer self-medicating presumptive TB among pharmacy clients for proper
            diagnosis and treatment being piloted by the Department of Health National TB Program (DOH-NTP) with technical assistance from Family Health
            International (FHI360) and mClinica through the TB Innovations and Health Systems Strengthening Project funded by the United States Agency of
            International Development (USAID)
          </p>
        </Grid>
        <Grid item className="intro-footer">
          <Grid item className="logos-container">
            <img className="logo-img" src={require('./assets/partners.png')} alt="partners" />
          </Grid>
          {isAuthorized ? (
            <Grid id="refer-patient-button" container alignItems="center">
              <BaseLink router={router} routeName="referral-form" className="refer-button">
                <span>REFER A PATIENT</span>
              </BaseLink>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  appStore: state.app
});

const mapDispatchToProps = ({ app }) => ({
  ...app
});

export default connect(mapStateToProps, mapDispatchToProps)(routeNode('intro-page')(Introduction));
