import axios from 'axios';
import stores from '../stores';
import store from 'store';
const { config } = window;

class ApiClient {
  async post(path, body) {
    const generatePayload = () => ({
      method: 'post',
      url: `${config.REACT_APP_API_URL}${path}`,
      data: body,
      responseType: 'json',
      headers: {
        'X-External-User-Id': store.get('userId')
      }
    });

    try {
      const results = await axios(generatePayload());
      return results;
    } catch (e) {
      const errorResponse = e.response;
      if (errorResponse.status === 401) {
        return errorResponse;
      }
      if (errorResponse.status === 400 && errorResponse.data.type === 'ValidationError') {
        return errorResponse;
      }
      stores.dispatch.ui.showPopupModal({
        type: 'danger',
        title: 'Error',
        message: `${e.message}`
      });

      return errorResponse;
    }
  }

  async get(path, qs) {
    const generatePayload = () => ({
      method: 'get',
      url: `${config.REACT_APP_API_URL}${path}`,
      params: qs,
      responseType: 'json'
    });

    try {
      const results = await axios(generatePayload());
      if (results.status === 200) {
        return results.data;
      } else {
        throw new Error('Network Error.');
      }
    } catch (e) {
      stores.dispatch.ui.showPopupModal({
        type: 'danger',
        title: 'Error',
        message: `${e.message}`
      });

      return null;
    }
  }
}

export default new ApiClient();
