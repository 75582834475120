import { init } from '@rematch/core';

const store = init({
  models: {
    ui: require('./ui.store').default,
    referral: require('./referral.store').default,
    facilities: require('./facilities.store').default,
    app: require('./app.store').default
  }
});

export default store;
